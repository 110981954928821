.Projects-container h2 {
  margin-bottom: var(--spacing-4);
}

.Projects-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
}

.Project-section {
  display: flex;
  gap: var(--spacing-4);
}

.Project-image img {
  border-radius: var(--spacing);
  box-shadow: 2px 6px 4px rgba(0, 0, 0, 0.1);
}

img.JobSeekerHome {
  max-width: 300px;
}

img.dogGoHome {
  max-width: 300px;
}

img.NinasCookbook {
  max-width: 580px;
}

.Project-section-ninas {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.Project-image-ninas {
  align-self: center;

}

.Project-image-ninas img {
  border-radius: var(--spacing);
  box-shadow: 2px 6px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (min-width: 750px){
  .Projects-content .Project-section:nth-child(even) {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 750px){
  .Project-section {
    flex-direction: column;
  }

  .Projects-container {
    width: 100%;
  }

  .Project-image {
    align-self: center;
  }
}