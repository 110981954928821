.Experience-content {
  display: flex;
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
  gap: var(--spacing-4)
}

.Experience-list ul {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   text-align: left;
   font-weight: 600;
   font-size: 1.3rem;
   padding-top: var(--spacing-3);
   padding-bottom: var(--spacing-3);
   gap: var(--spacing-5);
   cursor: pointer;
   width: 100%;
}

.Experience-details {
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
  width: 100%;
}

.Experience-details h3 {
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--Gray-dark);
}

.Experience-title {
  display: flex;
  justify-content: space-between;
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

.tasks {
  display: none;
}

.visible {
  display: block;
  animation: fadeIn 300ms ease-in-out both;
}

.tasks ul {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  list-style: inside;
  list-style-type: '📌  ';
}

.tasks p {
  line-height: var(--spacing-5);
  font-size: 1.2rem;
}

.tasks-sublist ul {
  list-style: inside;
  list-style-type: '> ';
  display: flex;
  gap: var(--spacing);
  padding-top: var(--spacing-2);
  padding-left: var(--spacing-3);
}

.tasks-ul {
  padding: var(--spacing-2);
  font-size: 1.1rem;
}

p.year {
  font-size: 1rem;
}

.bold {
  font-weight: 600;
}

.not-bold {
  font-weight: 500;
}

.Experience-list li {
  position: relative;
  z-index: 10;
}

.Experience-list li:before {
  z-index: 5;
  content: "";
  width: 100%;
  position: absolute;
  bottom: -12px;
  height: 3px;
  left: 0;
  display: block;
  background: var(--Djon);
  transform: translate3d(0,5px,0);
  transition: all .2s cubic-bezier(.175,.885,.32,1.275);
  opacity: 0;
}

.Experience-list li:nth-child(odd):hover:before {
  opacity: 1;
  transform: translateZ(0) rotate(1deg);
}

.Experience-list li.selected:nth-child(odd):before {
  opacity: 1;
  transform: translateZ(0) scale3d(1.1,1.1,1.1)  rotate(1deg);
}

.Experience-list li:nth-child(even):hover:before {
  opacity: 1;
  transform: translateZ(0) rotate(-1deg);
}

.Experience-list li.selected:nth-child(even):before {
  opacity: 1;
  transform: translateZ(0) scale3d(1.1,1.1,1.1)  rotate(-1deg);
}

@media screen and (min-width: 750px) {
  .Experience-details {
    height: 100%;
  }
  
}

@media screen and (max-width: 750px) {
  .Experience-content {
    flex-direction: column;
  }

  .Experience-list ul {
    flex-direction: row;
    gap: var(--spacing-3);
    padding-bottom: var(--spacing);
  }

  .Experience-list li {
    line-height: var(--spacing-3);
  }

  .Experience-title {
    flex-direction: column;
    line-height: var(--spacing-4);
  }

  .Experience-details {
    padding-top: 0;
  }

  .Experience-details li {
    line-height: var(--spacing-4);
  }

}
