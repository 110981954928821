.Contact-content {
  padding-top: var(--spacing-3);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Contact-content a {
  text-decoration: none;
  color: var(--Gray-dark);
}

.NinaBiking-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-right: var(--spacing-8);
}

.NinaBiking-content {
  max-width: 100%;
}

.Contact-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author p {
  text-align: right;
  font-size: 1.1rem;
}

@media screen and (min-width: 750px) {
  .Contact-content {
    width: 900px;
  }

}

@media screen and (max-width: 750px) {

  .Contact-container {
    width: 100%;
  }

  .Contact-bottom {
    flex-direction: column;
  }

  .NinaBiking-container {
    padding-right: 0;
    padding-bottom: var(--spacing-3);
  }

  .author p {
    text-align: center;
    font-size: 1.1rem !important;
  }
}

