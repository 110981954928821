:root {
  --White: #F2F8F8;
  --Black: #232320;
  --Gray-light: #7C778B;
  --Gray: #56535F;
  --Gray-dark: #3C3A42;
  --Bone: #FEFAF6;
  --Cotton-candy: #FFC4FF;
  --Djon: #B4B219;
  --spacing: 8px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-4: 32px;
  --spacing-5: 40px;
  --spacing-6: 48px;
  --spacing-7: 56px;
  --spacing-8: 64px;
  --spacing-9: 72px;
  --spacing-10: 80px;
}

@font-face {
  font-family: 'Biotif';
  src: local('Biotif'), url(./fonts/Biotif-Regular.ttf) format('opentype');
}

body {
  margin: 0;
  font-family: Biotif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--Gray);
  background-color: var(--Bone);
}

h1 {
  font-family: 'Domine', serif;
  font-size: 4rem;
  color: var(--Black);
}

h2 {
  font-family: 'Domine', serif;
  font-weight: 500;
  font-size: 1.8rem;
  color: var(--Black);
}

h3 {
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--Black);
}

p {
  line-height: var(--spacing-5);
  font-size: 1.3rem;
}




