.Nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-2);
  background-color: var(--Bone);
  z-index: 100;
}

.Nav-content a {
  text-decoration: none;
  color: var(--Black);
  font-weight: 600;
  font-size: 1.2rem;
}

.Nav-content nav {
  display: flex;
  gap: 52px;
}

nav a:before {
  z-index: 5;
  content: "";
  width: 100%;
  position: relative;
  bottom: -28px;
  height: 3px;
  left: 0;
  display: block;
  background: var(--Djon);
  transform: translate3d(0,5px,0);
  transition: all .2s cubic-bezier(.175,.885,.32,1.275);
  opacity: 0;
}

nav a:nth-child(odd):hover:before {
  opacity: 1;
  transform: translateZ(0) rotate(1deg);
}

nav a.selected:nth-child(odd):before {
  opacity: 1;
  transform: translateZ(0) scale3d(1.1,1.1,1.1)  rotate(1deg);
}

nav a:nth-child(even):hover:before {
  opacity: 1;
  transform: translateZ(0) rotate(-1deg);
}
nav a.selected:nth-child(even):before {
  opacity: 1;
  transform: translateZ(0) scale3d(1.1,1.1,1.1)  rotate(-1deg);
}


.Nav-icons {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-4);
}

.HomeIcon-image-container img{
  width: 26px;
  height: 26px;
}

/* MEDIA QUERIES */
@media screen and (max-width: 750px) {
  .Nav-content {
    top: 0;
    flex-direction: column;
    gap: var(--spacing-2);
    padding-top: var(--spacing-3);
  }

  .Nav-content a {
    font-size: 1.1rem;
  }

  .Nav-content nav {
    display: flex;
    justify-content: center;
    gap: var(--spacing-3);
    flex-wrap: wrap;
  }

  .Nav-icons {
    gap: var(--spacing-5)
  }

}
