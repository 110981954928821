.About-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.About-text {
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-4);
}

.About-text ul {
  list-style: circle;
  list-style: inside;
  list-style-type: '✔️ ';
}

.About-list {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  column-gap: var(--spacing-5);
  line-height: var(--spacing-6);
  font-size: 1.2rem;
}

.About-bottom-illustration {
  display: flex;
  justify-content: center;
  flex: 1;
}

.About-bottom {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

@media screen and (max-width: 750px) {
  .About-bottom {
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-2);

  }

  .About-list {
    display: inline-grid;
    grid-template-columns: auto auto;
    column-gap: var(--spacing-5);
  }
}